const ROOT = '/jmicro/JMICRO';

let vue = null;

let productMap = null

let deviceMap = null

let masterDeviceMap = null
let slaveDeviceMap = null

export default{

  ROOT,
  MNG : 'mng',
  LOG2LEVEL : {2:'LOG_DEBUG', 5:'LOG_ERROR', 6:'LOG_FINAL', 3:'LOG_INFO', 0:'LOG_NO',
	1: 'LOG_TRANCE', 4:'LOG_WARN'},

  RES_STATUS: {"1":"Uploading","2":"Ready","3":"Enable","4":"Error","5":"WAITING","6":"Download"},
  DEP_STATUS:{'1':'Draft','2':'Enable','3':"Check"},
  ROUTER_ROOT : ROOT + '/routeRules',
  CONFIG_ROOT : ROOT,
  AGENT_ROOT : ROOT + '/choreography/agents',
  INSTANCE_ROOT : ROOT + '/choreography/instances',
  DEP_ROOT : ROOT + '/choreography/deployments',

  RULE_ID: 'cn.jmicro.api.route.RouteRule',

  IOT : {
	  DEV_ROLE: {
			MASTER : 1,//主设备，可以通过主设备控制从设备
		  	SLAVE : 0,//从设备，可通过主设备控制从设备
		  	STANDALONE : 2,//独立设备，独立存在设备，不可以加入主设备，也不可以控制其他设备
			ALL : 3,
	  },
	  
	  EVENT_CODES : {
	  	ASR : 1, //语音命令
	  	IR : 2, //红外遥控
	  	KEY : 3, //物理按键
	  	OTHER : 4 ,//其他
		MQ : 5, //MQ传感器
		RAIN: 6, //雨滴传感器
		FIRE : 7, //火焰传感器
		INT : 8, //引脚电平变化中断
	  },
	  
	  JM_EVENT_CODE_LABELS:["语音识别","红外遥控","物理按键","其他","MQ传感器"],
	  
	  JM_EVENT_EXE : {
		SERVER : 1,//服务端执行
		LOCAL_DEV : 2, //局域网内设备执行
		THIRD : 3   //由三方系统执行
	  },
	  
	   JM_EVENT_EXE_LABELS:["服务端","本地设备","第三方系统"],
	   
	   JM_OP_SRC : {
			PRODUCT : 1,
			DEVICE : 2,
			
	   }, //USER : 3
	   //1:产品， 2： 设备,  3:用户
		JM_OP_SRC_LABELS:["产品","设备","用户"],
		
		JM_BOARDS :[
			{key:2, val: "面包板测试"}, 
			{key:3, val:"ESP01V2.7微控板"}, 
			{key:5, val:"ESP01微控制板"},
			{key:4, val:"86开关"},
			{key:6, val:"串口网卡"},
			{key:7, val:"ESP01磁保持继电器"},
		],
	   

	  getEventCodeLabel : function(eventCode) {
	  	return this.JM_EVENT_CODE_LABELS[parseInt(eventCode)-1]
	  },
	  
	  getDevRoleLabel : function(deviceRole) {
	  	return deviceRole==this.DEV_ROLE.MASTER ? '主设备':
	  	deviceRole==this.DEV_ROLE.SLAVE ? '从设备':'独立设备'
	  },
	  
	  isDevMaster : function(deviceRole) {
	  	return deviceRole==this.DEV_ROLE.MASTER
	  },
	  
	  isDevSlave : function(deviceRole) {
	  	return deviceRole==this.DEV_ROLE.SLAVE
	  },
	  
	  isDevStandalone : function(deviceRole) {
	  	return deviceRole==this.DEV_ROLE.STANDALONE
	  },
  },
  
    setVueIns(v) {
	  vue = v;
	},
  
  async getPrdMap() {
  	//产品的KEYVAlue集合
	if(productMap) return productMap
	
	console.log("jm->jr",vue.$jr)
  	let r = await vue.$jr.rpc.invokeByCode(-414142670, [])
  	console.log("getPrdMap result: ",r)
  	if(r.code != 0) {
  		 vue.$notify.error({title: '提示',message: r.msg});
  		return null
  	}
  	let pm = {}
  	for(let k in r.data) {
  		pm[k+''] = r.data[k]
  	}
	productMap = pm
  	//this.prdMap = pm
  	//console.log(this.prdMap)
	return productMap
  },
  
    async getMasterDevices(){
	   return this._loadMasterDevice(this.IOT.DEV_ROLE.MASTER)
	},
	
	async getSlaveDevices(){
	   return this._loadMasterDevice(this.IOT.DEV_ROLE.SLAVE)
	},
	
	async getDevices(){
	   return this._loadMasterDevice(this.IOT.DEV_ROLE.ALL)
	},
  
  async _loadMasterDevice(devType){
	  
	if(!deviceMap) {
		let r = await vue.$jr.rpc.invokeByCode(-2138649681, [this.IOT.DEV_ROLE.ALL])
		console.log("dev list", JSON.stringify(r),this.IOT.DEV_ROLE.ALL)
		if(r.code != 0) {
			vue.$notify.error({title: '提示',message: r.msg || '加载主设备数据错误'})
			return
		}
		
		if(!r.data || r.data.length == 0) {
			map["0"] = "" //防止重复加载无效请求
			console.log("无主设备数据")
			return
		}
		
		deviceMap = {}
		masterDeviceMap = {}
		slaveDeviceMap = {}
		
		for(let k in r.data) {
			//console.log(k + "=" + r.data[k])
			deviceMap[k] =  r.data[k]
			if(r.data[k].endWith("\\(主控\\)")) {
				masterDeviceMap[k] =  r.data[k]
			}else{
				slaveDeviceMap[k] =  r.data[k]
			}
		}
		
		console.log("deviceMap", deviceMap)
		console.log("masterDeviceMap", masterDeviceMap)
		console.log("slaveDeviceMap", slaveDeviceMap)
	}
	
	if(this.IOT.DEV_ROLE.ALL == devType ) {
		console.log("deviceMap", deviceMap)
		return deviceMap
	}else if(this.IOT.DEV_ROLE.MASTER == devType) {
		return masterDeviceMap
	}else if(this.IOT.DEV_ROLE.SLAVE == devType) {
		return slaveDeviceMap
	}
	return null

  },
  
  
	
}
